@media (max-width: 767px) {
  .skin-blue .main-header .navbar .dropdown-menu li a {
    color: #777;
  }
}

.message-fos-perso {
  border: 2px solid #00c0ef;
  margin: 50px;
  background-color: #ffffff;
  text-align: center;
}

.login-container {
  padding: 30px;
  max-width: 350px;
  width: 100% !important;
  background-color: #F7F7F7;
  margin: 100px auto;
  border-radius: 2px;
  border: 2px solid #00c0ef;
  overflow: hidden;
  font-family: "Source Sans Pro", sans-serif;

  h1 {
    text-align: center;
    font-size: 1.8em;
    font-family: "Source Sans Pro", sans-serif;
  }

  input[type=submit],
  button[type=submit] {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    position: relative;
  }
}

.login-container input[type=text],
input[type=email],
input[type=password] {
  height: 44px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top: 1px solid #c0c0c0;
  /* border-radius: 2px; */
  padding: 0 8px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.login-container input[type=text]:hover,
input[type=password]:hover {
  border: 1px solid #b9b9b9;
  border-top: 1px solid #a0a0a0;
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.login-submit {
  padding: 17px 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.1);
  background-color: #4d90fe;
  /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#4787ed)); */
  border: 0;
  /* border: 1px solid #3079ed; */

  &:hover {
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    background-color: #357ae8;
    /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#357ae8)); */
    border: 0;
    /* border: 1px solid #2f5bb7; */
  }
}

.login-container a {
  text-decoration: none;
  color: #666;
  font-weight: 400;
  text-align: center;
  display: inline-block;
  opacity: 0.6;
  transition: opacity ease 0.5s;
}

.login-help {
  font-size: 12px;
}

.erreur span {
  color: #cc1111;
}

.sidebar-collapse .menu_level_1 {
  display: none;
}
